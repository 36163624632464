import React from "react"
import Layout from "../layout/Layout"
import SEO from "../components/seo/seo"
import styled from "styled-components"
import PageHeader from "../components/pageHeader/PageHeader"
import AboutTop from "../pagesParts/about/AboutTop"
import AboutMid from "../pagesParts/about/AboutMid"
import AboutBot from "../pagesParts/about/AboutBot"

const StyledMainDiv = styled.main`
  margin-top: 60px;
`

export default function AboutPage() {
  return (
    <Layout>
      <SEO
        title="À propos de nous"
        description="Notre première mission est de vous sortir de situations délicates."
      />
      <StyledMainDiv>
        <PageHeader
          heading="À propos de nous"
          subheading="Nous intervenons sur toutes les technologies, anciennes ou modernes, et au passage nous assurons que votre identité est protégée et votre équipement informatique sécurisé (antivirus, antimalwares, backup, firewall, protection contre les vulnérabilités, etc.)."
        />
        <AboutTop />
        <AboutMid />
        <AboutBot />
      </StyledMainDiv>
    </Layout>
  )
}
